@use '@/styles/utils/mixins.scss' as *;

.galleryWrapper {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
        @include mob() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 20px;
        line-height: 1;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }
  }

  :global {
    .image-slider {
      padding: 24px 0;
    }
    .image-slider .swiper-thumb-slider {
      width: 100%;
      height: 100%;
    }
    .image-slider .swiper-big-slider {
      width: 100%;
      height: 100%;
    }
    .slider-col {
      position: relative;
    }
    .thumbNavigation {
      display: block;
      @include tab() {
        display: none;
      }
    }
    .mobileNavigation {
      display: none;
      @include tab() {
        display: block;
      }
    }
    .slider-prev,
    .slider-next {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $kiaMidnightBlack;
      width: 48px;
      height: 48px;
      border: 1px solid $kiaPolarWhite;
      border-radius: 50%;
      z-index: $sliderArrowsZIndex;
      transition: all 0.4s ease-out;
      cursor: pointer;
      position: absolute;
      z-index: 5;
      transform: translate(-50%);
      left: calc(50% - 24px);
      &:disabled {
        opacity: 0;
        pointer-events: none;
        @include tab() {
          opacity: 1;
          pointer-events: all;
        }
      }
      &:before {
        content: '';
      }

      &:hover {
        background-color: black;
      }

      @include tab() {
        position: static;
        transform: translate(0);
        left: initial;
        background-color: transparent;
        border: 0;
        svg {
          height: 100%;
          width: 100%;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
    .slider-prev {
      top: 0;
      rotate: 90deg;
      @include tab() {
        rotate: 0deg;
      }
    }
    .slider-next {
      bottom: 0;
      rotate: -90deg;
      @include tab() {
        rotate: 0deg;
      }
    }
    .slider-thumbs {
      height: 600px;
      @include tab() {
        height: 120px;
      }
      @include mob() {
        height: 80px;
      }
    }
    .slider-thumbs .slider-image {
      transition: 0.25s;
      opacity: 0.9;
      cursor: pointer;
    }
    .slider-thumbs .slider-image:hover {
      opacity: 1;
    }
    .slider-thumbs .swiper-slide-thumb-active .slider-image {
      opacity: 1;
    }

    @include mob() {
      .slider-thumbs .swiper-slide {
        width: 146.4px;
        margin-right: 16px;
      }
    }

    @include tab-min() {
      .slider-thumbs .swiper-wrapper {
        flex-direction: column;
      }

      .slider-thumbs .swiper-slide {
        height: 138px;
        margin-bottom: 16px;
      }
    }

    .slider-images {
      height: 600px;
      width: 100%;

      @include mob() {
        height: 240px;
      }
    }
    .slider-images .slider-image img {
      transition: 3s;
    }

    @include mob() {
      .slider-image.thumb {
        width: 146.4px;
        overflow: hidden;
      }
    }

    .slider-image {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .slider-image img {
      display: block;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    .gallerySwiperControl {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0 auto;
      @include tab() {
        justify-content: flex-start;
      }
    }
    .gallerySwiperNavigation {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-left: 2px;
    }
    .swiper-navigation {
      display: flex;
      align-items: center;
    }

    .swiper-pagination {
      background-color: $keyline;
      border-radius: 15px;
      color: $kiaMidnightBlack;
      padding: 5px 10px;
      position: static;
      bottom: 0;
      right: 0;
      width: auto;
      left: auto;
      margin-right: 2px;
      line-height: 1;
      min-width: 48px;
      text-align: center;
      span {
        font-size: 12px;
        line-height: 14.4px;
      }
    }
  }
  .btnTextLinkBorderless {
    padding: 13px 0;
    background-color: transparent;
    border: 0;
    color: $kiaMidnightBlack;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    position: relative;
    display: block;
    border-radius: 0;
    @media screen and (max-width: $minTablet) {
      padding-bottom: 0;
    }
    &:hover,
    &:active,
    &:focus {
      z-index: $headerNavItemZindex;
      background-color: transparent;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 11px;
      height: 1px;
      width: 0;
      background-color: $kiaMidnightBlack;
      transition: width 0.3s;
    }
    &:hover::before,
    &:active::before {
      width: 100%;
      transition: width 0.3s;
    }
    &.active::before {
      width: 100%;
      transition: width 0.3s;
    }
  }
}

.fullScreenModal {
  &Body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    :global {
      .swiper {
        width: 100%;
      }
      .swiper-modal-slider {
        height: 100%;
        width: 100%;
      }
      .slider-image {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: auto;
          max-height: 100vh;
        }
      }
      .slider-prev-modal,
      .slider-next-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $kiaMidnightBlack;
        width: 48px;
        height: 48px;
        border: 1px solid $kiaPolarWhite;
        border-radius: 50%;
        z-index: $sliderArrowsZIndex;
        transition: all 0.4s ease-out;
        cursor: pointer;

        &:before {
          content: '';
        }

        &:hover {
          background-color: black;
        }
      }
      .slider-prev-modal {
        left: 10px;
      }
      .slider-next-modal {
        rotate: -180deg;
      }
      .galleryModalSwiperNavigation {
        position: absolute;
        z-index: 5;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
      }
    }
  }
  &CloseBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    background-color: $kiaCoolGreyTransparent;
    color: $kiaMidnightBlack;
    border: 1px solid $kiaCoolGreyTransparent;
    transition: all 0.3s ease-in-out;
    svg {
      height: 16px;
      width: 16px;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $kiaPolarWhite;
    }
  }
  &Gallery {
    position: relative;
    display: none;
    &:fullscreen {
      display: block;
    }
  }
  &Content {
    background: $kiaMidnightBlack !important;
    :global {
      .swiper-modal-slider {
        height: 100%;
        width: 100%;
      }
      .slider-image {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: auto;
          max-height: 100vh;
        }
      }
      .slider-prev-modal,
      .slider-next-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $kiaMidnightBlack;
        width: 48px;
        height: 48px;
        border: 1px solid $kiaPolarWhite;
        border-radius: 50%;
        z-index: $sliderArrowsZIndex;
        transition: all 0.4s ease-out;
        cursor: pointer;

        &:before {
          content: '';
        }

        &:hover {
          background-color: black;
        }
      }
      .slider-prev-modal {
        left: 10px;
      }
      .slider-next-modal {
        rotate: -180deg;
      }
      .galleryModalSwiperNavigation {
        position: absolute;
        z-index: 5;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
      }
    }
  }
}
